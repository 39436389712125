import React, { useState } from "react";

const InputRange = ({ label, min, max, step, defaultValue, handleChange }) => {
  const [value, setValue] = useState(defaultValue);
  const onChange = (val) => {
    setValue(val);
    handleChange(val);
  };
  return (
    <div className="range-input-wrap">
      {label !== "" ? (
        <div className="range-input-label">{label.toUpperCase()}</div>
      ) : (
        ""
      )}
      <input
        type="range"
        className="range-input"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default InputRange;

import React from "react";
import { Block } from "./Block";
import { useRanges } from "./hooks";

export const SetBlock = ({ weight, reps, rpe, changeReps, changeRpe }) => {
  const { ranges } = useRanges(changeReps, changeRpe);
  return (
    <Block label="SET" text={`${weight}x${reps}@${rpe}`} ranges={ranges} />
  );
};

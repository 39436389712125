import React, { useState, useEffect } from "react";
import { Block } from "./Block";
import { useRanges } from "./hooks";
import { rpeScale } from "./const";

export const PredictedSetBlock = ({ weight, reps, rpe }) => {
  const [predictedWeight, setPredictedWeight] = useState(0);
  const [predictedReps, setPredictedReps] = useState(1);
  const [predictedRpe, setPredictedRpe] = useState(6);
  const { ranges } = useRanges(setPredictedReps, setPredictedRpe);

  useEffect(() => {
    const rpePercentage = rpeScale[reps][rpe];
    const predictedRpePercentage = rpeScale[predictedReps][predictedRpe];
    const calculation = (weight * predictedRpePercentage) / rpePercentage;
    setPredictedWeight(
      calculation % 1 === 0 ? calculation : calculation.toFixed(1)
    );
  }, [weight, reps, rpe, predictedReps, predictedRpe]);

  return (
    <Block
      classnames={["predicted-block-wrap"]}
      label="PREDICTED SET"
      text={`${predictedWeight}x${predictedReps}@${predictedRpe}`}
      ranges={ranges}
    />
  );
};

import React, { useState, useEffect, useMemo } from "react";
import { Block } from "./Block";
import { createRange } from "./util";

export const PercentageBlock = ({ weight }) => {
  const [calculatedWeight, setCalculatedWeight] = useState(weight);
  const [percentage, setPercentage] = useState(100);
  const ranges = useMemo(
    () => [createRange("", 60, 120, 1, 100, setPercentage)],
    []
  );

  useEffect(() => {
    const calculated = (weight * percentage) / 100;
    setCalculatedWeight(calculated);
  }, [weight, percentage]);

  return (
    <Block
      classnames={["percentage-block-wrap"]}
      label="PERCENTAGE"
      text={`${percentage}% of ${weight}=${calculatedWeight}`}
      ranges={ranges}
    />
  );
};

import React, { useState } from "react";
import { Header } from "./Components/Header";
import { SetBlock } from "./Components/SetBlock";
import { PredictedSetBlock } from "./Components/PredictedSetBlock";
import { PercentageBlock } from "./Components/PercentageBlock";
import { Footer } from "./Components/Footer";
import { rpeScale } from "./Components/const";

export const App = () => {
  const [weight, setWeight] = useState(0);
  const [action, setAction] = useState("+");
  const [reps, setReps] = useState(1);
  const [rpe, setRpe] = useState(6);

  const changeWeight = (newWeight) => {
    const calcWeight = action === "+" ? weight + newWeight : weight - newWeight;
    setWeight(calcWeight);
  };

  const changeReps = (value) => {
    setReps(value);
  };

  const changeRpe = (value) => {
    setRpe(value);
  };

  const toggleAction = () => {
    setAction(action === "+" ? "-" : "+");
  };

  return (
    <div>
      <Header
        handleClick={changeWeight}
        handleActionToggle={toggleAction}
        action={action}
      />
      <SetBlock
        weight={weight}
        reps={reps}
        rpe={rpe}
        changeReps={changeReps}
        changeRpe={changeRpe}
      />
      <PredictedSetBlock weight={weight} reps={reps} rpe={rpe} />
      <PercentageBlock weight={weight} />
      <Footer />
    </div>
  );
};

import React from "react";
import InputRange from "./InputRange";

export const Block = ({ classnames = [], label, text, ranges }) => (
  <div className={["block-wrap", ...classnames].join(" ")}>
    <div className="description-wrap">
      <div className="block-label">{label}</div>
      <div className="calculated-values">{text}</div>
    </div>
    {ranges.map(({ label, min, max, step, defaultValue, handleChange }) => (
      <InputRange
        key={label}
        label={label}
        min={min}
        max={max}
        step={step}
        defaultValue={defaultValue}
        handleChange={handleChange}
      />
    ))}
  </div>
);

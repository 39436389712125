import React, { useEffect, useState } from "react";
import { rpeScale, repRange } from "./const";
const rpeValues = Object.keys(rpeScale[1]).sort((a, b) => b - a);

export const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalClassnames, setModalClassnames] = useState("modal");

  useEffect(() => {
    const cls = ["modal"];
    if (isOpen) cls.push("open");
    setModalClassnames(cls.join(" "));
  }, [isOpen]);

  return (
    <>
      <footer>
        <div className="left">
          <button className="open-modal" onClick={() => setIsOpen(true)}>
            ABOUT
          </button>
        </div>
        <div className="right">
          <a href="https://www.instagram.com/theliz6/" target="blank">
            @theliz6
          </a>
        </div>
      </footer>
      <div className={modalClassnames}>
        <div className="modal-box">
          <button className="close-modal" onClick={() => setIsOpen(false)}>
            <img src="/close.png" alt="Close modal icon" />
          </button>
          <summary>
            <h1>What is RPE?</h1>
            <p>
              RPE, or rate of perceived exertion, is a subjective measure of
              difficulty for a given set. The concept of RPE originated in
              endurance training and was introduced to powerlifting by Mike
              Tuchscherer.
            </p>
            <p>
              RPE is based on a scale from 1 to 10, but it's mostly used in
              ranges 6 to 10. A set at RPE 10 would be a true max effort set,
              without any reps left in the tank. A set at RPE 9 means that you
              could have definitely done 1 more rep. A set at 9.5 means that you
              could have maybe done 1 more rep. A set at 8 means that you could
              have definitely done 2 more reps, while a set at 8.5 means that
              you could have maybe done 2 more rep. Same goes for 7, 6, etc.
            </p>
            <p>
              Based on RPE and rep combination, you can calculate an estimation
              of your E1RM, or any other rep/RPE combination. The percentages
              that we use are as follows:
            </p>
            <div className="table-wrap">
              <table className="rpe-table">
                <thead>
                  <tr>
                    <th></th>
                    {rpeValues.map((rpe) => (
                      <th key={rpe}>{rpe}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {repRange.map((rep) => (
                    <tr key={rep}>
                      <td>{rep}</td>
                      {rpeValues.map((rpe) => (
                        <td key={rpe}>{rpeScale[rep][rpe]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <h1>About the app</h1>
            <p>
              This app is envisioned as a simple and fast, mobile friendly RPE
              calculator.
            </p>
            <p>
              For the best user experience, add this website to your home
              screen. It will behave as a native application and can be used
              while you are offline. Instructions can be found{" "}
              <a
                href="https://support.apple.com/en-gb/guide/iphone/iph42ab2f3a7/ios#:~:text=Add%20a%20website%20icon%20to%20your%20Home%20Screen,Home%20Screen%20for%20quick%20access.&text=%2C%20scroll%20down%20the%20list%20of,device%20where%20you%20add%20it."
                target="blank"
              >
                here
              </a>{" "}
              for iPhone users or{" "}
              <a
                href="https://www.androidauthority.com/add-website-android-iphone-home-screen-3181682/"
                target="blank"
              >
                here
              </a>{" "}
              for Android users.
            </p>
            <p>
              For all inquires, feel free to contact the author via{" "}
              <a href="mailto:tinvalinc@gmail.com">
                email (tinvalinc@gmail.com)
              </a>{" "}
              or{" "}
              <a href="https://www.instagram.com/theliz6/" target="blank">
                instagram (@theliz6)
              </a>
            </p>
          </summary>
        </div>
      </div>
    </>
  );
};

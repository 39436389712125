import React from "react";

const actionButtons = [2.5, 5, 10, 20, 50, 100];

export const Header = ({ handleClick, handleActionToggle, action }) => (
  <div className="action-buttons">
    {actionButtons.map((weight) => (
      <button key={weight} onClick={() => handleClick(weight)}>
        {action}
        {weight}
      </button>
    ))}
    <button className="toggle-action" onClick={handleActionToggle}>
      {action}
    </button>
  </div>
);

import { useMemo } from "react";
import { createRange } from "./util";

export const useRanges = (changeReps, changeRpe) => {
  const ranges = useMemo(
    () => [
      createRange("reps", 1, 12, 1, 1, changeReps),
      createRange("rpe", 5, 10, 0.5, 6, changeRpe),
    ],
    [changeReps, changeRpe]
  );
  return { ranges };
};
